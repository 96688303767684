export function getDynamicComponents() {
  return {
    "navbar-link-bar-component": () => import("../components/layouts/NavbarLinkBarComponent.vue"),
    "navbar-announcement-bar-component": () => import("../components/layouts/NavbarAnnouncementBarComponent.vue"),
    "user-settings-component": () => import("../components/layouts/UserSettingsComponent.vue"),
    "changelog-component": () => import("../components/pages/changelog/ChangelogComponent.vue"),
    "notification-panel-component": () => import("../mobile-components/pages/notifications/NotificationPanelComponent.vue"),
    "notification-icon-component": () => import("../components/common/NotificationMainIconComponent.vue"),

    "edit-profile-component": () => import("../components/pages/profile/EditProfileComponent.vue"),
    "user-list-component": () => import("../components/pages/user/UserListComponent.vue"),
    "group-list-component": () => import("../components/pages/group/GroupListComponent.vue"),
    "task-channel-list-component": () => import("../components/pages/task-channel/TaskChannelListComponent.vue"),
    "task-category-list-component": () => import("../components/pages/task-category/TaskCategoryListComponent.vue"),
    "task-status-list-component": () => import("../components/pages/task-status/TaskStatusListComponent.vue"),
    "hashtag-list-component": () => import("../components/pages/hashtag/HashtagListComponent.vue"),
    "announcement-list-component": () => import("../components/pages/announcement/AnnouncementListComponent.vue"),
    "contractor-statuses-component": () => import("../components/pages/contractor-status/ContractorStatusListComponent.vue"),

    "dashboard-component": () => import("../components/pages/posts/DashboardComponent.vue"),
    "chat-form": () => import("../components/ChatForm.vue"),
    "tasks-list-component": () => import("../components/pages/tasks/ListComponent.vue"),
    "single-task": () => import("../components/pages/task/SingleTaskComponent.vue"),
    "contractors-list-component": () => import("../components/pages/contractors/ContractorsListComponent.vue"),
    "footer-layout-component": () => import("../components/layouts/FooterLayoutComponent.vue"),
    "item-with-tooltip": () => import("../components/UI/tooltips/ItemWithTooltip.vue"),
    "login-component": () => import("../components/auth/LoginComponent.vue"),
    "login-body": () => import("../components/auth/LoginBody.vue"),
    "password-reset-body": () => import("../components/auth/PasswordResetBody.vue"),
    "filters-list-component": () => import("../components/pages/user-filters/UserFiltersListComponent.vue"),
    "auto-logout-page-component": () => import("../components/errors/AutoLogoutComponent.vue"),
    "error-page-component": () => import("../components/errors/ErrorComponent.vue"),
    "task-field-mandatory": () => import("../components/pages/task-field-mandatory/TaskFieldMandatoryPage.vue"),
    "tenants-panel": () => import("../components/pages/tenants/TenantsPanel.vue"),
  }
}
